import React from "react";
import { KitchenScene } from "./molecules/kitchen/KitchenScene";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./molecules/HomePage";
import Kitchen02 from "./molecules/Kitchen02";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/kitchen" element={<KitchenScene />} />
          <Route path="/kitchen-02" element={<Kitchen02 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
