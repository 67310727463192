import { create } from "zustand";

const useStore = create((set) => ({
  isModalOpen: false,
  isModelswapped: false,
  isMinimodalopen: false,
  selectedProduct: null,
  relatedProducts: [],
  selectedfloor: "default",
  selectedProducts: [
    { type: "table", id: "table_01" },
    { type: "fridge", id: "fridge_01_1" },
    { type: "mixie", id: "mixie_01_1" },
    { type: "stove", id: "stove_01" },
  ],
  selectedTable: "table_01",
  selectedChair: "chair_01",
  selectedMat: "mat_01",
  selectedPot: "pot_01",
  selectedFlower: "plant_01",
  selectedLight: "light_01",
  selectedFridge: "fridge_01",
  selectedMixie: "mixie_01",
  selectedSmalloven: "oven_small_01",
  selectedStove: "stove_02",
  selectedSink: "sink_01",
  selectedLargeoven: "oven_large_01",
  selectedTap: "tap_01",
  selectedCooker: "cooker_01",
  selectedDishwash: "dishwasher_01",

  newmodel_url: "",
  cameraPosition: [0, 1, 6],
  cameraTarget: [0, 0, 0],
  titlePosition: "back",
  isContextvisible: false,
  isAnimating: false,
  cameraRef: null,
  orbitRef: null,
  swapModel: () => set({ isModelswapped: true }),
  revokeModel: () => set({ isModelswapped: false }),
  openModal: () => set({ isModalOpen: true }),
  closeModal: () => set({ isModalOpen: false }),
  showContextMenu: (x, y) =>
    set({ isContextvisible: true, position: { x, y } }),
  hideContextMenu: () => set({ isContextvisible: false }),
  setSelectedProduct: (product) => set({ selectedProduct: product }),
  setNewmodel_url: (url) => set({ newmodel_url: url }),
  setSelectedTable: (product) => set({ selectedTable: product }),
  setTitlePosition: (value) => set({ titlePosition: value }),
  setSelectedFridge: (value) => set({ selectedFridge: value }),
  setSelectedMixie: (value) => set({ selectedMixie: value }),
  setSelectedStove: (value) => set({ selectedStove: value }),
  setSelectedLight: (value) => set({ selectedLight: value }),
  setSelectedMat: (value) => set({ selectedMat: value }),
  setRelatedProducts: (value) => set({ relatedProducts: value }),
  startAnimation: () => set({ isAnimating: true }),
  stopAnimation: () => set({ isAnimating: false }),
  setCamera: (position, target) =>
    set({ cameraPosition: position, cameraTarget: target }),
  setCameraRef: (ref) => set({ cameraRef: ref }),
  setOrbitRef: (ref) => set({ orbitRef: ref }),
  setSelectedCooker: (value) => set({ selectedCooker: value }),
  setSelectedChair: (value) => set({ selectedChair: value }),
  setSelectedPot: (value) => set({ selectedPot: value }),
  setSelectedFlower: (value) => set({ selectedFlower: value }),
  setSelectedSmalloven: (value) => set({ selectedSmalloven: value }),
  setSelectedLargeoven: (value) => set({ selectedLargeoven: value }),
  setSelectedSink: (value) => set({ selectedSink: value }),
  setSelectedTap: (value) => set({ selectedTap: value }),
  setSelectedDishwash: (value) => set({ selectedDishwash: value }),
  SetIsMinimodalopen: (value) => set({ isMinimodalopen: value }),
  setSelectedfloor: (value) => set({ selectedfloor: value }),
}));

export default useStore;
