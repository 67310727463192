import { create } from "zustand";

const Kitchen02Store = create((set) => ({
  cameraRef: null,
  selectedProduct: null,

  selectedTable: "table_01",
  table_url:
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/output/table_01.glb",

  selectedChair: "chair_01",
  chair_url:
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/output/chair_01.glb",

  selectedProductCategory: [],

  setSelectedProductCategory: (value) =>
    set({ selectedProductCategory: value }),
  is_ProductCatalogueVisible: false,
  setTable_url: (value) => set({ table_url: value }),
  setSelectedTable: (value) => set({ selectedTable: value }),

  setChair_url: (value) => set({ chair_url: value }),
  setSelectedChair: (value) => set({ selectedChair: value }),

  setIs_ProductCatalogueVisible: (value) =>
    set({ is_ProductCatalogueVisible: value }),
  setSelectedProduct: (value) => set({ selectedProduct: value }),
  setCameraRef: (ref) => set({ cameraRef: ref }),
}));

export default Kitchen02Store;
