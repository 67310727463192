import { Canvas } from "@react-three/fiber";
import {
  Sky,
  Bvh,
  OrbitControls,
  PerspectiveCamera,
  Environment,
} from "@react-three/drei";
import { Selection } from "@react-three/postprocessing";
import { KitchenAssets } from "./KitchenAssets";
import Model from "../../components/Model";
import { Suspense, useEffect, useRef } from "react";
import Overlay from "../../components/Overlay";
import KitchenEffects from "./KitchenEffects";
import useStore from "../../Store";
import ContextMenu from "../../components/ContextMenu";
import { MathUtils } from "three";
// import KitchenCameraButton from "./KitchenCameraButton";
import MiniModel from "../../components/MiniModel";


const CameraComponent = () => {
  const { setCameraRef } = useStore();
  const cameraRef = useRef();
  const controlsRef = useRef();

  useEffect(() => {
    if (cameraRef.current) {
      setCameraRef(cameraRef.current);
    }
  }, [setCameraRef]);

  return (
    <>
      <PerspectiveCamera makeDefault ref={cameraRef} position={[0, 0, 5]} />
      <OrbitControls
        minPolarAngle={MathUtils.degToRad(70)}
        ref={controlsRef}
        enablePan={false}
        enableZoom={true}
        // minAzimuthAngle={-Math.PI / 4} 
        // maxAzimuthAngle={Math.PI / 4}  
        // minPolarAngle={ -Math.PI / 8}    
        maxPolarAngle={Math.PI/2} 
        // maxPolarAngle={MathUtils.degToRad(30)}

      />
    </>
  );
};

export const KitchenScene = () => {
  const showContextMenu = useStore((state) => state.showContextMenu);
  const { isContextvisible, hideContextMenu } = useStore();

  const handleContextMenu = (event) => {
    event.preventDefault();

    if (isContextvisible) {
      hideContextMenu();
    }
    showContextMenu(event.pageX, event.pageY);
  };

  return (
    <div onContextMenu={handleContextMenu} onClick={() => hideContextMenu()}>
      <Suspense fallback={<Overlay />}>
        <Canvas flat dpr={[1, 1.5]} gl={{ antialias: false }} shadows>
        
          <Environment preset="sunset" background exposure={0.5} />

          
          <directionalLight
            position={[-5, 10, -5]}    
            intensity={2}              
            castShadow                 
            shadow-mapSize-width={2048} 
            shadow-mapSize-height={2048}
            shadow-camera-far={50}
            shadow-camera-left={-10}
            shadow-camera-right={10}
            shadow-camera-top={10}
            shadow-camera-bottom={-10}
            shadow-bias={-0.002}       
            shadow-radius={1} 
          />

          
          <pointLight
            position={[0,1.2, -2]}     
            intensity={2}            
            distance={50}             
            decay={3}                
          />

          <CameraComponent />
          <Sky />
          <Bvh firstHitOnly>
            <Selection>
              <KitchenEffects />
              <KitchenAssets
                rotation={[0, Math.PI / 2, 0]}
                position={[0, -1, 0]}
                castShadow          
                receiveShadow       
              />
            </Selection>
          </Bvh>
        </Canvas>
      </Suspense>
      <Model />
      <MiniModel />
      <ContextMenu />
    </div>
  );
};
