import React from "react";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-full bg-slate-300 flex flex-col gap-2 p-6">
      <div className="flex-inline">
        <h1>
          This is a homepage -{" "}
          <button
            className="bg-slate-800 px-3 py-1 text-white rounded-xl"
            onClick={() => navigate("/kitchen")}
          >
            Kitchen
          </button>
        </h1>
      </div>
      <div>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus
        natus maxime tempora!
      </div>
    </div>
  );
}

export default HomePage;
