import React from "react";

function Overlay() {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <p className="text-2xl py-2 px-4 bg-gray-200 rounded-md">
        Please Wait.. We are processing your scene...
      </p>
    </div>
  );
}

export default Overlay;
