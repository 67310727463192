import React from "react";
import useStore from "../Store";
import { FaEye, FaHeart, FaShoppingCart } from "react-icons/fa";
import { Md360, MdOutlineSwitchLeft } from "react-icons/md";
import { FaWandMagicSparkles } from "react-icons/fa6";

function ContextMenu() {
  const { isContextvisible, position, hideContextMenu } = useStore();

  const handleClick = () => {
    hideContextMenu();
  };

  return (
    <>
      {isContextvisible && (
        <div
          className="absolute z-50 bg-white border border-gray-300 rounded shadow-lg w-[280px]"
          style={{ top: `${position.y}px`, left: `${position.x}px` }}
          onClick={handleClick}
        >
          <div className="flex flex-col  px-3 py-2 rounded-sm">
            <p className="text-xs text-slate-400 cursor-default p-0">
              Customization
            </p>
            <div className="flex gap-4 px-3 py-2 items-center cursor-pointer hover:bg-slate-100">
              <FaWandMagicSparkles className="text-xl text-slate-500" />
              <div>Generate Random Scene</div>
            </div>

            <div className="flex gap-4 px-3 py-2 items-center cursor-pointer hover:bg-slate-100">
              <MdOutlineSwitchLeft className="text-xl text-slate-500" />
              <div>Switch Next Product</div>
            </div>
            <div className="bg-slate-300 h-[.5px] my-2 w-full"></div>
            <p className="text-xs text-slate-400 cursor-default p-0">
              Product info
            </p>

            <div className="flex gap-4 px-3 py-2 items-center cursor-pointer hover:bg-slate-100">
              <FaEye className="text-xl text-slate-500" />
              <div>View Product</div>
            </div>

            <div className="flex gap-4 px-3 py-2 items-center cursor-pointer hover:bg-slate-100">
              <Md360 className="text-xl text-slate-500" />
              <div>View 360</div>
            </div>
            <div className="bg-slate-300 h-[.5px] m-2 w-full"></div>
            <p className="text-xs text-slate-400 cursor-default">Ordering</p>
            <div className="flex gap-4 px-3 py-2 items-center cursor-pointer hover:bg-slate-100 hover:font-bold">
              <FaHeart className="text-sm text-slate-500" />
              <p className="font-light">Add to Wishlist</p>
            </div>
            <div className="flex gap-4 px-3 py-2 items-center cursor-pointer hover:bg-slate-100 hover:font-bold">
              <FaShoppingCart className="text-sm text-slate-500" />
              <p className="font-light">Add to Cart</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ContextMenu;
