import React, { useRef, useState } from "react";
import { MathUtils } from "three";

function KitchenHotspot({ position, onClick, color, scale }) {
  const mesh = useRef();
  const [hovered, setHovered] = useState(false);

  const handlePointerOver = (e) => {
    e.stopPropagation();
    setHovered(true); // Set hovered state to true
    document.body.style.cursor = "pointer"; // Change cursor to pointer
  };

  const handlePointerOut = (e) => {
    e.stopPropagation();
    setHovered(false); // Set hovered state to false
    document.body.style.cursor = "auto"; // Reset cursor
  };

  return (
    <mesh
      ref={mesh}
      position={position}
      scale={scale}
      rotation={[MathUtils.degToRad(-90), 0, 0]}
      onClick={onClick}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
    >
      <sphereGeometry args={[0.3]} />
      <circleGeometry args={[0.24, 64]} />
      <meshBasicMaterial color={hovered ? "#D6C66E" : "#f5f5dc"} />
    </mesh>
  );
}

export default KitchenHotspot;
