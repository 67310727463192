import { useState, useCallback, useRef, useEffect } from "react";
import { useGLTF, useEnvironment, Text, useHelper } from "@react-three/drei";
import { Select } from "@react-three/postprocessing";
import useStore from "../../Store";
import axios from "axios";
import { SpotLightHelper } from "three";
import gsap from "gsap";
import debounce from "lodash/debounce";
import KitchenHotspot from "./KitchenHotspot";
import { Html } from '@react-three/drei';



const NewHotspot = ({ position, onClick,value }) => {
    return (
      <group position={position}>
        {/* 3D Sphere representing the hotspot */}
        <mesh onClick={onClick}>
          <sphereGeometry args={[0.05]} />
          <meshBasicMaterial color="black" />
        </mesh>

        {/* 2D Text label for the hotspot */}
        <Html distanceFactor={8}>
          <div className="px-2 py-1 text-xs font-semibold text-white rounded-sm bg-black/20">
            {value}
          </div>
        </Html>
      </group>
    );
  };

export function KitchenAssets(props) {
  const glb_url =
    "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/virtual-tour/kitchen.glb";
  const local_glb = "/assets/model/kitchen.glb";
  const { nodes, materials } = useGLTF(glb_url);
  const {
    openModal,
    setSelectedProduct,
    setRelatedProducts,
    startAnimation,
    isMinimodalopen,
    SetIsMinimodalopen,
  } = useStore();
  const [productsData, setProductsData] = useState([]);
  const cameraRef = useStore((state) => state.cameraRef);

  //Camera ANimations :
  const clickedFrontCamera = () => {
    // alert("clicked Front Camera");
    const newPosition = { x: 2, y: 0, z: 1 }; // New camera position
    const lookAtPosition = { x: 0, y: 0, z: 20 };
    gsap.to(cameraRef.position, {
      duration: 2,
      x: newPosition.x,
      y: newPosition.y,
      z: newPosition.z,
      onUpdate: () => {
        // Ensure the camera updates its matrix after each lookAt call
        cameraRef.lookAt(lookAtPosition.x, lookAtPosition.y, lookAtPosition.z);
        cameraRef.updateMatrixWorld();
      },
      onComplete: () => {
        // Final lookAt to ensure the camera is correctly oriented
        cameraRef.lookAt(lookAtPosition.x, lookAtPosition.y, lookAtPosition.z);
      },
    });
  };

  const clickedLeftCamera = () => {
    // alert("clicked Left Camera");
    const newPosition = { x: -3, y: 1, z: -3 }; // New camera position
    const lookAtPosition = { x: 0, y: 0, z: 0 };
    gsap.to(cameraRef.position, {
      duration: 2,
      x: newPosition.x,
      y: newPosition.y,
      z: newPosition.z,
      onUpdate: () => {
        // Update the camera's look-at position during the animation
        cameraRef.lookAt(lookAtPosition.x, lookAtPosition.y, lookAtPosition.z);
      },
    });
  };

  const clickedRightCamera = () => {
    const newPosition = { x: 3, y: 1, z: -3 }; // New camera position
    const lookAtPosition = { x: 4, y: 0, z: 0 };
    gsap.to(cameraRef.position, {
      duration: 2,
      x: newPosition.x,
      y: newPosition.y,
      z: newPosition.z,
      onUpdate: () => {
        // Update the camera's look-at position during the animation
        cameraRef.lookAt(lookAtPosition.x, lookAtPosition.y, lookAtPosition.z);
      },
    });
  };

  const clickedBackCamera = () => {
    const newPosition = { x: 1, y: 0.6, z: -4 }; // New camera position
    const lookAtPosition = { x: 0, y: 0, z: 0 };
    gsap.to(cameraRef.position, {
      duration: 3,
      x: newPosition.x,
      y: newPosition.y,
      z: newPosition.z,
      onUpdate: () => {
        // Update the camera's look-at position during the animation
        cameraRef.lookAt(lookAtPosition.x, lookAtPosition.y, lookAtPosition.z);
      },
    });
  };

  const switchCamera = (id) => {
    console.log("button clicked");
    startAnimation();
    if (id === 1) {
      if (cameraRef.current) {
        console.log("button clicked");
        gsap.to(cameraRef.current.position, {
          duration: 2,
          z: 1, // Move forward
          onUpdate: () => {
            cameraRef.updateProjectionMatrix();
          },
        });
      }
    } else if (id === 2) {
      const targetPosition = { x: -4, y: 2, z: -2 }; // Replace with your target coordinates
      const targetLookAt = { x: 0, y: 1, z: 0 };
      if (cameraRef) {
        gsap.to(cameraRef.position, {
          duration: 2,
          x: targetPosition.x,
          y: targetPosition.y,
          z: targetPosition.z,
          onUpdate: () => {
            cameraRef.lookAt(targetLookAt.x, targetLookAt.y, targetLookAt.z);
            cameraRef.updateProjectionMatrix();
          },
        });
      }
    }
  };

  useEffect(() => {
    const fetchProductData = async () => {
      const response = await fetch("/assets/json/products-data.json");
      const data = await response.json();
      setProductsData(data);
    };

    fetchProductData();
  }, []);

  const handleClick = (asset_id, category) => {
    const selectedProduct = productsData.find(
      (product) => product.asset === asset_id
    );

    const relatedProduct = productsData.filter(
      (product) => product.category === category
    );

    if (relatedProduct || selectedProduct) {
      setRelatedProducts(relatedProduct);
      setSelectedProduct(selectedProduct);
      openModal();
      console.log(relatedProduct);
    } else {
      console.error("Related Products not found");
    }
  };

  const env = useEnvironment({ preset: "park" });
  const [hovered, hover] = useState(null);
  // const debouncedHover = useCallback(debounce(hover, 30), []);
  // const over = (name) => (e) => (e.stopPropagation(), debouncedHover(name));

  const debouncedHover = useCallback(
    debounce((name) => hover(name), 30),
    [hover]
  );

  const over = (name) => (e) => {
    e.stopPropagation();
    debouncedHover(name);
  };

  const {
    selectedTable,
    selectedChair,
    selectedFridge,
    selectedPot,
    selectedSmalloven,
    selectedLargeoven,
    selectedSink,
    selectedTap,
    selectedMixie,
    selectedStove,
    selectedDishwash,
    selectedFlower,
    titlePosition,
    selectedLight,
    selectedMat,
    selectedCooker,
    selectedfloor,
  } = useStore();

  const clickFunction = (product) => {
    fetchData(product);
    alert("model selected" + product);
  };

  const fetchData = async (product) => {
    try {
      const response = await axios.get("/modaldata.json");

      const data = response.data;
      const filteredData = data.filter((item) => item.id === product);

      if (filteredData.length > 0) {
        setSelectedProduct(filteredData[0]);
        console.log(filteredData);
      } else {
        console.log("No data found for the product:", product);
        alert("No data found for the specified product.");
      }
    } catch (err) {
      alert(err);
    } finally {
    }
  };

  const spotlightRef = useRef();
  useHelper(spotlightRef, SpotLightHelper);
  return (
    <>
      <group {...props} dispose={null}>
        {/* <mesh
          geometry={nodes.floor_01.geometry}
          material={materials.floor_03}
          onClick={() => SetIsMinimodalopen(!isMinimodalopen)}
        /> */}


    <NewHotspot position={[0, 0, -3]} value={'Floor'} onClick={() => SetIsMinimodalopen(!isMinimodalopen)}  />



        {selectedfloor === "default" && (
          <mesh
            geometry={nodes.walls_2.geometry}

            material={materials.kitchen}
            castShadow
            receiveShadow
          />
        )}
        {selectedfloor === "floor_01" && (
          <mesh
            geometry={nodes.floor_01.geometry}
            material={materials.floor_01}
            castShadow
            receiveShadow

          />
        )}
        {selectedfloor === "floor_02" && (
          <mesh
            geometry={nodes.floor_02.geometry}
            material={materials.floor_02}
            castShadow
            receiveShadow


          />
        )}

        {selectedfloor === "floor_03" && (
          <mesh
            geometry={nodes.floor_03.geometry}
            material={materials.floor_03}
            castShadow
            receiveShadow

          />
        )}
        {selectedTable === "table_01" && (
          <Select
            castShadow
            enabled={hovered === "WOODSTAGE Sheesham dining table - $150"}
            onPointerOver={over("WOODSTAGE Sheesham dining table - $150")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("table_01", "table")}
          >
            <mesh
              geometry={nodes.table_01.geometry}
              material={materials["kitchen.001"]}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedTable === "table_02" && (
          <Select
            castShadow
            enabled={hovered === "Furnire Carp Solid dining table - $200"}
            onPointerOver={over("Furnire Carp Solid dining table - $200")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("table_02", "table")}
          >
            <>
              <mesh
                geometry={nodes.table_02_1.geometry}
                material={materials.table_02_01}
                material-envMap={env}
                castShadow
                receiveShadow
              />
              <mesh
                geometry={nodes.table_02_2.geometry}
                material={materials.table_02_02}
                  castShadow
                receiveShadow
              />
            </>
          </Select>
        )}
        {selectedTable === "table_03" && (
          <Select
            castShadow
            enabled={
              hovered === "Porash Furniture Sheesham dining table - $240"
            }
            onPointerOver={over(
              "Porash Furniture Sheesham dining table - $240"
            )}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("table_03", "table")}
          >
            <mesh
              geometry={nodes.table_03.geometry}
              material={materials.table_03}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedChair === "chair_01" && (
          <Select
            castShadow
            enabled={hovered === "Winntage Furniture Sheesham chair - $100"}
            onPointerOver={over("Winntage Furniture Sheesham chair - $100")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("chair_01", "chair")}
          >
            <>
              <mesh
                geometry={nodes.chair_01_1.geometry}
                material={materials["kitchen.001"]}
                castShadow
                receiveShadow
              />
              <mesh
                geometry={nodes.chair_01_2.geometry}
                material={materials.seat}
                castShadow
                receiveShadow
              />
            </>
          </Select>
        )}
        {selectedChair === "chair_02" && (
          <Select
            castShadow
            enabled={hovered === "MoonWooden Sheesham chair - $200"}
            onPointerOver={over("MoonWooden Sheesham chair - $200")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("chair_02", "chair")}
          >
            <>
              <mesh
                geometry={nodes.chair_02_1.geometry}
                material={materials.chair_02_wood}
                castShadow
                receiveShadow
              />
              <mesh
                geometry={nodes.chair_02_2.geometry}
                material={materials.chair_02_fabric}
                castShadow
                receiveShadow
              />
            </>
          </Select>
        )}
        {selectedChair === "chair_03" && (
          <Select
            castShadow
            enabled={hovered === "Zeshouse Mid Century - $189"}
            onPointerOver={over("Zeshouse Mid Century - $189")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("chair_03", "chair")}
          >
            <mesh
              geometry={nodes.chair_03.geometry}
              material={materials.chair_03}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedMat === "mat_01" && (
          <Select
            castShadow
            receiveShadow
            enabled={hovered === "Claiez Anti Fatigue Kitchen table mat - $30"}
            onPointerOver={over("Claiez Anti Fatigue Kitchen table mat - $30")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("mat_01", "mat")}
          >
            <mesh
              geometry={nodes.mat_01.geometry}
              material={materials.mat_01}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedMat === "mat_02" && (
          <Select
            castShadow
            
            receiveShadow
            enabled={hovered === "Bulfyss Multipurpose Kitchen table mat - $35"}
            onPointerOver={over("Bulfyss Multipurpose Kitchen table mat - $35")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("mat_02", "mat")}
          >
            <mesh
              geometry={nodes.mat_02.geometry}
              material={materials.mat_02}
              castShadow
            
              receiveShadow
            />
          </Select>
        )}
        {selectedMat === "mat_03" && (
          <Select
            castShadow
            receiveShadow
            enabled={hovered === "wolpin Cotton Kitchen Floor Mat - $40"}
            onPointerOver={over("wolpin Cotton Kitchen Floor Mat - $40")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("mat_03", "mat")}
          >
            <mesh
              geometry={nodes.mat_03.geometry}
              material={materials.mat_03}
              castShadow
            
              receiveShadow
            />
          </Select>
        )}
        {selectedMat === "mat_04" && (
          <Select
            castShadow
            receiveShadow
            enabled={hovered === "Ishro Home Kitchen Mat - $45"}
            onPointerOver={over("Ishro Home Kitchen Mat - $45")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("mat_04", "mat")}
          >
            <mesh
              geometry={nodes.mat_04.geometry}
              material={materials.mat_04}
              castShadow
            
              receiveShadow
            />
          </Select>
        )}
        {selectedLight === "light_01" && (
          <Select
            castShadow
            enabled={hovered === "ADWAIT Glass Hanging Luster - $40"}
            onPointerOver={over("ADWAIT Glass Hanging Luster - $40")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("light_01", "light")}
          >
            <mesh
              geometry={nodes.light_01.geometry}
              material={materials.light_01}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedLight === "light_02" && (
          <Select
            castShadow
            enabled={hovered === "VRCT Antique Design Lantern - $50"}
            onPointerOver={over("VRCT Antique Design Lantern - $50")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("light_02", "light")}
          >
            <>
              <mesh
                geometry={nodes.light_02_1.geometry}
                material={materials.light_02_1}
                castShadow
                receiveShadow
              />
              <mesh
                geometry={nodes.light_02_2.geometry}
                material={materials.light_02_2}
                castShadow
                receiveShadow
              />
            </>
          </Select>
        )}
        {selectedLight === "light_03" && (
          <Select
            castShadow
            enabled={hovered === "Whiteray Traditional Round - $60"}
            onPointerOver={over("Whiteray Traditional Round - $60")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("light_03", "light")}
          >
            <mesh
              geometry={nodes.light_03.geometry}
              material={materials.light_03}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedLight === "light_04" && (
          <Select
            castShadow
            enabled={hovered === "VRCT E27 Base Adjustable - $70"}
            onPointerOver={over("VRCT E27 Base Adjustable - $70")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("light_04", "light")}
          >
            <>
              <mesh
                geometry={nodes.light_04_1.geometry}
                material={materials.light_04_1}
                castShadow
                receiveShadow
              />
              <mesh
                geometry={nodes.light_04_2.geometry}
                material={materials.light_04_2}
                castShadow
                receiveShadow
              />
              <mesh
                geometry={nodes.light_04_3.geometry}
                material={materials.light_04_3}
                castShadow
                receiveShadow
              />
            </>
          </Select>
        )}
        {selectedPot === "pot_01" && (
          <Select
            castShadow
            enabled={hovered === "Mosaic Glass Flower Pot - $200"}
            onPointerOver={over("Mosaic Glass Flower Pot - $200")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("pot_01", "pot")}
          >
            <mesh
              geometry={nodes.pot_01.geometry}
              material={materials.light_01}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedPot === "pot_02" && (
          <Select
            castShadow
            enabled={hovered === "Generic SSG Bottle Shape pot - $115"}
            onPointerOver={over("Generic SSG Bottle Shape pot - $115")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("pot_02", "pot")}
          >
            <mesh
              geometry={nodes.pot_02.geometry}
              material={materials.pot_02}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedPot === "pot_03" && (
          <Select
            castShadow
            enabled={hovered === "SPHINX Decorative Glass Vase pot - $210"}
            onPointerOver={over("SPHINX Decorative Glass Vase pot - $210")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("pot_03", "pot")}
          >
            <mesh
              geometry={nodes.pot_03.geometry}
              material={materials.pot_03}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedPot === "pot_04" && (
          <Select
            castShadow
            enabled={hovered === "Wood flower Pot - $75"}
            onPointerOver={over("Wood flower Pot - $75")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("pot_04", "pot")}
          >
            <mesh
              geometry={nodes.pot_04.geometry}
              material={materials.pot_04}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        <mesh geometry={nodes.kitchen.geometry} material={materials.kitchen} />
        <mesh geometry={nodes.walls_1.geometry} material={materials.walls_1} />
        {selectedFridge === "fridge_01" && (
          <Select
            castShadow
            enabled={hovered === "Midea 591L Side By Side Refrigerator - $500"}
            onPointerOver={over("Midea 591L Side By Side Refrigerator - $500")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("fridge_01", "fridge")}
          >
            <mesh
              geometry={nodes.fridge_01.geometry}
              material={materials.fridge_01}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedFridge === "fridge_02" && (
          <Select
            castShadow
            enabled={
              hovered === "Haier 596L, 3-Star Side By Side Refrigerator - $550"
            }
            onPointerOver={over(
              "Haier 596L, 3-Star Side By Side Refrigerator - $550"
            )}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("fridge_02", "fridge")}
          >
            <mesh
              geometry={nodes.fridge_02.geometry}
              material={materials.fridge_02}
            />
          </Select>
        )}
        {selectedSmalloven === "oven_small_01" && (
          <Select
            castShadow
            enabled={hovered === "AGARO Marvel 9L Oven Toaster - $250"}
            onPointerOver={over("AGARO Marvel 9L Oven Toaster - $250")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("oven_small_01", "oven_small")}
          >
            <>
              <mesh
                geometry={nodes.oven_small_01_1.geometry}
                material={materials.oven_small_01_1}
                castShadow
                receiveShadow
              />
              <mesh
                geometry={nodes.oven_small_01_2.geometry}
                material={materials.oven_small_01_2}
                castShadow
                receiveShadow
              />
            </>
          </Select>
        )}
        {selectedSmalloven === "oven_small_02" && (
          <Select
            castShadow
            enabled={hovered === "Pigeon by Stovekraft Oven Toaster - $200"}
            onPointerOver={over("Pigeon by Stovekraft Oven Toaster - $200")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("oven_small_02", "oven_small")}
          >
            <mesh
              geometry={nodes.oven_small_02.geometry}
              material={materials.oven_small_02}
              castShadow
              receiveShadow
            />
          </Select>
        )}
        {selectedSmalloven === "oven_small_03" && (
          <Select
            castShadow
            enabled={hovered === "Panasonic 20L Solo Microwave Oven - $173"}
            onPointerOver={over("Panasonic 20L Solo Microwave Oven - $173")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("oven_small_03", "oven_small")}
          >
            <>
              <mesh
                geometry={nodes.oven_small_03_1.geometry}
                material={materials.oven_small_03_1}
                castShadow
                receiveShadow
              />
              <mesh
                geometry={nodes.oven_small_03_2.geometry}
                material={materials.oven_small_03_2}
                castShadow
                receiveShadow
              />
            </>
          </Select>
        )}
        {selectedStove === "stove_01" && (
          <Select
            castShadow
            enabled={hovered === "CUKOR Electric stove - $150"}
            onPointerOver={over("CUKOR Electric stove - $150")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("stove_01", "stove")}
          >
            <>
              <mesh
                geometry={nodes.gas_stove_01_1.geometry}
                material={materials.gas_stove_01_1}
              />
              <mesh
                geometry={nodes.gas_stove_01_2.geometry}
                material={materials.gas_stove_01_2}
              />
              <mesh
                geometry={nodes.gas_stove_01_3.geometry}
                material={materials.gas_stove_01_3}
              />
              <mesh
                geometry={nodes.gas_stove_01_4.geometry}
                material={materials.gas_stove_01_4}
              />
            </>
          </Select>
        )}
        {selectedStove === "stove_02" && (
          <>
            <Select
              castShadow
              enabled={hovered === "Cosmo F969 Dual Fuel - $1499"}
              onPointerOver={over("Cosmo F969 Dual Fuel - $1499")}
              onPointerOut={() => debouncedHover(null)}
              onClick={() => handleClick("stove_02", "stove")}
            >
              <mesh
                geometry={nodes.gas_stove_02_1.geometry}
                material={materials.gas_stove_02_01}
              />
              <mesh
                geometry={nodes.gas_stove_02_2.geometry}
                material={materials.gas_stove_02_02}
              />
              <mesh
                geometry={nodes.gas_stove_02_3.geometry}
                material={materials.gas_stove_02_03}
              />
            </Select>
          </>
        )}
        {selectedStove === "stove_03" && (
          <>
            <Select
              castShadow
              enabled={hovered === "ZLINE 30 Duel Fuel - $2500"}
              onPointerOver={over("ZLINE 30 Duel Fuel - $2500")}
              onPointerOut={() => debouncedHover(null)}
              onClick={() => handleClick("stove_03", "stove")}
            >
              <mesh
                geometry={nodes.gas_stove_03_1.geometry}
                material={materials.gas_stove_03_01}
              />
              <mesh
                geometry={nodes.gas_stove_03_2.geometry}
                material={materials.gas_stove_03_02}
              />
              <mesh
                geometry={nodes.gas_stove_03_3.geometry}
                material={materials.gas_stove_03_03}
              />
            </Select>
          </>
        )}
        {selectedSink === "sink_01" && (
          <Select
            castShadow
            enabled={hovered === "Clay Plus Kitchen Sink - $240"}
            onPointerOver={over("Clay Plus Kitchen Sink - $240")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("sink_01", "sink")}
          >
            <mesh
              geometry={nodes.sink_01.geometry}
              material={materials.sink_1}
            />
          </Select>
        )}
        {selectedSink === "sink_02" && (
          <Select
            castShadow
            enabled={hovered === "ProssimaGen kitchen sink - $240"}
            onPointerOver={over("ProssimaGen kitchen sink - $240")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("sink_02", "sink")}
          >
            <mesh
              geometry={nodes.sink_02_1.geometry}
              material={materials.sink_02_01}
            />
            <mesh
              geometry={nodes.sink_02_2.geometry}
              material={materials.sink_02_02}
            />
          </Select>
        )}
        {selectedSink === "sink_03" && (
          <Select
            castShadow
            enabled={
              hovered ===
              "Ruhe Quartz Single Bowl 24X18X9 Inches Kitchen Sink - $240"
            }
            onPointerOver={over(
              "Ruhe Quartz Single Bowl 24X18X9 Inches Kitchen Sink - $240"
            )}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("sink_03", "sink")}
          >
            <mesh
              geometry={nodes.Sink_03.geometry}
              material={materials.sink_03_02}
            />
            <mesh
              geometry={nodes.Sink_03_1.geometry}
              material={materials.sink_03_01}
            />
          </Select>
        )}
        {selectedTap === "tap_01" && (
          <Select
            castShadow
            enabled={
              hovered ===
              "MN Collection Turbo Sink Cocke for Kitchen Sink - $240"
            }
            onPointerOver={over(
              "MN Collection Turbo Sink Cocke for Kitchen Sink - $240"
            )}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("tap_01", "tap")}
          >
            <>
              <mesh
                geometry={nodes.tap_01_1.geometry}
                material={materials.tap_01_1}
              />
              <mesh
                geometry={nodes.tap_01_2.geometry}
                material={materials.tap_01_2}
              />
            </>
          </Select>
        )}
        {selectedTap === "tap_02" && (
          <Select
            castShadow
            enabled={hovered === "10X Sink Tap for Kitchen - $240"}
            onPointerOver={over("10X Sink Tap for Kitchen - $240")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("tap_02", "tap")}
          >
            <mesh
              geometry={nodes.tap_02.geometry}
              material={materials.tap_02}
            />
          </Select>
        )}
        {selectedTap === "tap_03" && (
          <Select
            castShadow
            enabled={
              hovered === "OM CLAER Tap Extender for Kitchen Sink - $240"
            }
            onPointerOver={over(
              "OM CLAER Tap Extender for Kitchen Sink - $240"
            )}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("tap_03", "tap")}
          >
            <mesh
              geometry={nodes.tap_03.geometry}
              material={materials.tap_03}
            />
          </Select>
        )}
        {selectedMixie === "mixie_01" && (
          <Select
            castShadow
            enabled={hovered === "Preethi Eco Plus Mixer Grinder - $80"}
            onPointerOver={over("Preethi Eco Plus Mixer Grinder - $80")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("mixie_01", "mixie")}
          >
            <>
              <mesh
                geometry={nodes.mixie_01_1.geometry}
                material={materials.mixie_01_1}
              />
              <mesh
                geometry={nodes.mixie_01_2.geometry}
                material={materials.mixie_01_2}
              />
            </>
          </Select>
        )}
        {selectedMixie === "mixie_02" && (
          <Select
            castShadow
            enabled={hovered === "Hamilton Beach Professional - $90"}
            onPointerOver={over("Hamilton Beach Professional - $90")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("mixie_02", "mixie")}
          >
            <>
              <mesh
                geometry={nodes.mixie_02_1.geometry}
                material={materials.mixie_02_1}
              />
              <mesh
                geometry={nodes.mixie_02_2.geometry}
                material={materials.mixie_02_2}
              />
            </>
          </Select>
        )}
        {selectedMixie === "mixie_03" && (
          <Select
            castShadow
            enabled={hovered === "Philips HL7756/01 750 Watt Mixer - $90"}
            onPointerOver={over("Philips HL7756/01 750 Watt Mixer - $90")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("mixie_03", "mixie")}
          >
            <>
              <mesh
                geometry={nodes.mixie_03_1.geometry}
                material={materials.mixie_03_1}
              />
              <mesh
                geometry={nodes.mixie_03_2.geometry}
                material={materials.mixie_03_2}
              />
            </>
          </Select>
        )}
        {selectedMixie === "mixie_04" && (
          <Select
            castShadow
            enabled={hovered === "Prestige Iris Plus 750 W Mixer Grinder - $90"}
            onPointerOver={over("Prestige Iris Plus 750 W Mixer Grinder - $90")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("mixie_04", "mixie")}
          >
            <>
              <mesh
                geometry={nodes.mixie_04_1.geometry}
                material={materials.mixie_04_1}
              />
              <mesh
                geometry={nodes.mixie_04_2.geometry}
                material={materials.mixie_04_2}
              />
              <mesh
                geometry={nodes.mixie_04_3.geometry}
                material={materials.mixie_04_3}
              />
            </>
          </Select>
        )}
        {selectedCooker === "cooker_01" && (
          <Select
            castShadow
            enabled={hovered === "COSORI Electric Pressure Cooker - $40"}
            onPointerOver={over("COSORI Electric Pressure Cooker - $40")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("cooker_01", "cooker")}
          >
            <>
              <mesh
                geometry={nodes.cooker_01_1.geometry}
                material={materials.cooker_01_1}
              />
              <mesh
                geometry={nodes.cooker_01_2.geometry}
                material={materials.cooker_01_2}
              />
            </>
          </Select>
        )}
        {selectedCooker === "cooker_02" && (
          <Select
            castShadow
            enabled={hovered === "Narcissus Electric pressure Cooker - $70"}
            onPointerOver={over("Narcissus Electric pressure Cooker - $70")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("cooker_02", "cooker")}
          >
            <mesh
              geometry={nodes.cooker_02.geometry}
              material={materials.cooker_02}
            />
          </Select>
        )}
        {selectedFlower === "plant_01" && (
          <>
            <Select
              castShadow
              enabled={hovered === "Artificial Flowers - $95"}
              onPointerOver={over("Artificial Flowers - $95")}
              onPointerOut={() => debouncedHover(null)}
              onClick={() => handleClick("plant_01", "flower")}
            >
              <mesh
                geometry={nodes.plant_1_1.geometry}
                material={materials.plant_1_1}
              />
              <mesh
                geometry={nodes.plant_1_2.geometry}
                material={materials.plant_1_2}
              />
            </Select>
          </>
        )}
        {selectedDishwash === "dishwasher_01" && (
          <>
            <Select
              castShadow
              enabled={hovered === "Bosh Dishwasher - $230"}
              onPointerOver={over("Bosh Dishwasher - $230")}
              onPointerOut={() => debouncedHover(null)}
              onClick={() => handleClick("dishwasher_01", "dishwasher")}
            >
              <mesh
                geometry={nodes.dishwasher_01_1.geometry}
                material={materials.dishwasher_01_1}
              />
              <mesh
                geometry={nodes.dishwasher_01_2.geometry}
                material={materials.dishwasher_01_2}
              />
              <mesh
                geometry={nodes.dishwasher_01_3.geometry}
                material={materials.dishwasher_01_3}
              />
              <mesh
                geometry={nodes.dishwasher_01_4.geometry}
                material={materials.dishwasher_01_4}
              />
              <mesh
                geometry={nodes.dishwasher_01_5.geometry}
                material={materials.dishwasher_01_5}
              />
            </Select>
          </>
        )}
        {selectedDishwash === "dishwasher_02" && (
          <>
            <Select
              castShadow
              enabled={hovered === "Voltas Beko dishwasher - $240"}
              onPointerOver={over("Voltas Beko dishwasher - $240")}
              onPointerOut={() => debouncedHover(null)}
              onClick={() => handleClick("dishwasher_02", "dishwasher")}
            >
              <mesh
                geometry={nodes.dishwasher_02_1.geometry}
                material={materials.dishwasher_02_1}
              />
              <mesh
                geometry={nodes.dishwasher_02_2.geometry}
                material={materials.dishwasher_02_2}
              />
              <mesh
                geometry={nodes.dishwasher_02_3.geometry}
                material={materials.dishwasher_02_3}
              />
              <mesh
                geometry={nodes.dishwasher_02_4.geometry}
                material={materials.dishwasher_02_4}
              />
              <mesh
                geometry={nodes.dishwasher_02_5.geometry}
                material={materials.dishwasher_02_5}
              />
              <mesh
                geometry={nodes.dishwasher_02_6.geometry}
                material={materials.dishwasher_02_6}
              />
              <mesh
                geometry={nodes.dishwasher_02_7.geometry}
                material={materials.dishwasher_02_7}
              />
            </Select>
          </>
        )}
        {selectedDishwash === "dishwasher_03" && (
          <>
            <Select
              castShadow
              enabled={hovered === "Samsung Dishwasher - $390"}
              onPointerOver={over("Samsung Dishwasher - $390")}
              onPointerOut={() => debouncedHover(null)}
              onClick={() => handleClick("dishwasher_03", "dishwasher")}
            >
              <mesh
                geometry={nodes.dishwasher_03_1.geometry}
                material={materials.dishwasher_03_1}
              />
              <mesh
                geometry={nodes.dishwasher_03_2.geometry}
                material={materials.dishwasher_03_2}
              />
              <mesh
                geometry={nodes.dishwasher_03_3.geometry}
                material={materials.dishwasher_03_3}
              />
            </Select>
          </>
        )}
        {selectedLargeoven === "oven_large_01" && (
          <Select
            castShadow
            enabled={hovered === "Carysil FSCR-04 Electric Oven - $180"}
            onPointerOver={over("Carysil FSCR-04 Electric Oven - $180")}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("oven_large_01", "oven_large")}
          >
            <>
              <mesh
                geometry={nodes.oven_large_01_1.geometry}
                material={materials.oven_large_01_1}
              />
              <mesh
                geometry={nodes.oven_large_01_2.geometry}
                material={materials.oven_large_01_2}
              />
              <mesh
                geometry={nodes.oven_large_01_3.geometry}
                material={materials.oven_large_01_3}
              />
              <mesh
                geometry={nodes.oven_large_01_4.geometry}
                material={materials.oven_large_01_4}
              />
              <mesh
                geometry={nodes.oven_large_01_5.geometry}
                material={materials.oven_large_01_5}
              />
              <mesh
                geometry={nodes.oven_large_01_6.geometry}
                material={materials.oven_large_01_6}
              />
              <mesh
                geometry={nodes.oven_large_01_7.geometry}
                material={materials.oven_large_01_7}
              />
            </>
          </Select>
        )}
        {selectedLargeoven === "oven_large_02" && (
          <Select
            castShadow
            enabled={
              hovered ===
              "KAFF OV81 GIKF 81 Litre Multi-Functional Built-in Oven - $200"
            }
            onPointerOver={over(
              "KAFF OV81 GIKF 81 Litre Multi-Functional Built-in Oven - $200"
            )}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("oven_large_02", "oven_large")}
          >
            <>
              <mesh
                geometry={nodes.oven_large_02_1.geometry}
                material={materials.oven_large_02_1}
              />
              <mesh
                geometry={nodes.oven_large_02_2.geometry}
                material={materials.oven_large_02_2}
              />
              <mesh
                geometry={nodes.oven_large_02_3.geometry}
                material={materials.oven_large_02_3}
              />
              <mesh
                geometry={nodes.oven_large_02_4.geometry}
                material={materials.oven_large_02_4}
              />
              <mesh
                geometry={nodes.oven_large_02_5.geometry}
                material={materials.oven_large_02_5}
              />
              <mesh
                geometry={nodes.oven_large_02_6.geometry}
                material={materials.oven_large_02_6}
              />
              <mesh
                geometry={nodes.oven_large_02_7.geometry}
                material={materials.oven_large_02_7}
              />
            </>
          </Select>
        )}
        {selectedLargeoven === "oven_large_03" && (
          <Select
            castShadow
            enabled={
              hovered === "KAFF KOV 73 MRFT 73 Litre Built-in Oven - $90"
            }
            onPointerOver={over(
              "KAFF KOV 73 MRFT 73 Litre Built-in Oven - $90"
            )}
            onPointerOut={() => debouncedHover(null)}
            onClick={() => handleClick("oven_large_03", "oven_large")}
          >
            <>
              <mesh
                geometry={nodes.oven_large_03_1.geometry}
                material={materials.oven_large_03_1}
              />
              <mesh
                geometry={nodes.oven_large_03_2.geometry}
                material={materials.oven_large_03_2}
              />
              <mesh
                geometry={nodes.oven_large_03_3.geometry}
                material={materials.oven_large_03_3}
              />
              <mesh
                geometry={nodes.oven_large_03_4.geometry}
                material={materials.oven_large_03_4}
              />
              <mesh
                geometry={nodes.oven_large_03_5.geometry}
                material={materials.oven_large_03_5}
              />
              <mesh
                geometry={nodes.oven_large_03_6.geometry}
                material={materials.oven_large_03_6}
              />
              <mesh
                geometry={nodes.oven_large_03_7.geometry}
                material={materials.oven_large_03_7}
              />
              <mesh
                geometry={nodes.oven_large_03_8.geometry}
                material={materials.oven_large_03_8}
              />
              <mesh
                geometry={nodes.oven_large_03_9.geometry}
                material={materials.oven_large_03_9}
              />
              <mesh
                geometry={nodes.oven_large_03_10.geometry}
                material={materials.oven_large_03_10}
              />
            </>
          </Select>
        )}
      </group>
      {titlePosition === "front" && (
        <Text
          position={[1, 1.25, -2]}
          color="black"
          fontSize={0.15}
          font="Inter-Regular.woff"
          letterSpacing={-0.05}
        >
          {hovered ? hovered : "Modular Kitchen Interior"}
        </Text>
      )}

      {titlePosition === "back" && (
        <Text
          position={[1, 1.25, -2]}
          color="black"
          fontSize={0.15}
          font="Inter-Regular.woff"
          letterSpacing={-0.05}
        >
          {hovered ? hovered : "Modular Kitchen Interior"}
        </Text>
      )}

      <KitchenHotspot
        onClick={clickedFrontCamera}
        position={[0.3, -0.98, 1.4]}
      />

      {/* LeftCamera */}
      <KitchenHotspot
        onClick={clickedRightCamera}
        position={[2.8, -0.98, -0.7]}
      />

      {/* RightCamera */}
      <KitchenHotspot
        onClick={clickedLeftCamera}
        position={[-2.5, -0.98, -0.9]}
      />

      {/* BackCamera */}
      <KitchenHotspot onClick={clickedBackCamera} position={[0.3, -0.98, -3]} />
    </>
  );
}
