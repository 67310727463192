import React, { Suspense, useEffect, useRef, useState } from "react";
import Overlay from "../components/Overlay";
import { Canvas, useThree } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,
  PerspectiveCamera,
  useGLTF,
  Select,
} from "@react-three/drei";
import Kitchen02Store from "../stores/Kitchen02Store";
import { MathUtils } from "three";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import axios from "axios";
import { div } from "three/examples/jsm/nodes/Nodes.js";

function Table({ url }) {
  const [scene, setScene] = useState(null);
  const { invalidate } = useThree();
  const { scene: loadedScene } = useGLTF(url);

  useEffect(() => {
    if (loadedScene) {
      setScene(loadedScene);
      invalidate();
    }
  }, [url, loadedScene, invalidate]);

  if (!scene) return null;

  return <primitive object={scene} scale={[1, 1, 1]} />;
}

function Asset(props) {
  const { nodes, materials } = useGLTF(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/virtual-tour/kitchen02.glb"
  );
  const { selectedTable, table_url, selectedChair, chair_url } =
    Kitchen02Store();

  return (
    <>
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mat_01.geometry}
          material={materials.mat_01}
        />

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.kitchen.geometry}
          material={materials["kitchen.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.pot_01.geometry}
          material={materials.light_01}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.sink_01.geometry}
          material={materials.sink_1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.light_01.geometry}
          material={materials.light_01}
        />

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.fridge_01.geometry}
          material={materials.fridge_01}
        />

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.floor_01.geometry}
          material={materials.floor_01}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.walls_1.geometry}
          material={materials.walls_1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.walls_2.geometry}
          material={materials.kitchen}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.chair_01_1.geometry}
          material={materials["kitchen.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.chair_01_2.geometry}
          material={materials.seat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.plant_1_1.geometry}
          material={materials.plant_1_1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.plant_1_2.geometry}
          material={materials.plant_1_2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mixie_01_1.geometry}
          material={materials.mixie_01_1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mixie_01_2.geometry}
          material={materials.mixie_01_2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.oven_small_01_1.geometry}
          material={materials.oven_small_01_1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.oven_small_01_2.geometry}
          material={materials.oven_small_01_2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.cooker_01_1.geometry}
          material={materials.cooker_01_1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.cooker_01_2.geometry}
          material={materials.cooker_01_2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.tap_01_1.geometry}
          material={materials.tap_01_1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.tap_01_2.geometry}
          material={materials.tap_01_2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.gas_stove_01_1.geometry}
          material={materials.gas_stove_01_1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.gas_stove_01_2.geometry}
          material={materials.gas_stove_01_2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.gas_stove_01_3.geometry}
          material={materials.gas_stove_01_3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.gas_stove_01_4.geometry}
          material={materials.gas_stove_01_4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.dishwasher_01_1.geometry}
          material={materials.dishwasher_01_1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.dishwasher_01_2.geometry}
          material={materials.dishwasher_01_2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.dishwasher_01_3.geometry}
          material={materials.dishwasher_01_3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.dishwasher_01_4.geometry}
          material={materials.dishwasher_01_4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.dishwasher_01_5.geometry}
          material={materials.dishwasher_01_5}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.oven_large_01_1.geometry}
          material={materials.oven_large_01_1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.oven_large_01_2.geometry}
          material={materials.oven_large_01_2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.oven_large_01_3.geometry}
          material={materials.oven_large_01_3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.oven_large_01_4.geometry}
          material={materials.oven_large_01_4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.oven_large_01_5.geometry}
          material={materials.oven_large_01_5}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.oven_large_01_6.geometry}
          material={materials.oven_large_01_6}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.oven_large_01_7.geometry}
          material={materials.oven_large_01_7}
        />
      </group>
    </>
  );
}

const CameraComponent = () => {
  const { setCameraRef } = Kitchen02Store();
  const cameraRef = useRef();
  const controlsRef = useRef();

  useEffect(() => {
    if (cameraRef.current) {
      setCameraRef(cameraRef.current);
    }
  }, [setCameraRef]);

  return (
    <>
      <PerspectiveCamera
        makeDefault
        // ref={cameraRef}
        position={[0, 5, 0]}
        rotation={[0, 2, 0]}
      />
      <OrbitControls
        minPolarAngle={MathUtils.degToRad(70)}
        ref={controlsRef}
        enablePan={false}
        enableZoom={true}
        maxPolarAngle={Math.PI / 2}
      />
    </>
  );
};

function Kitchen02() {
  const {
    setSelectedProduct,
    selectedProduct,
    is_ProductCatalogueVisible,
    setIs_ProductCatalogueVisible,
    setSelectedTable,
    selectedTable,
    setTable_url,
    table_url,
    setSelectedChair,
    selectedChair,
    setChair_url,
    chair_url,
  } = Kitchen02Store();
  const [filteredData, setFilteredData] = useState([]);

  const handleclickIcon = (value) => {
    setSelectedProduct(value);
    setIs_ProductCatalogueVisible(true);
    fetchProductsData(value);
  };
  const handleTableSelection = (value, url) => {
    if (selectedTable !== value) {
      setSelectedTable(value);
      setTable_url(url);
    }
    console.log(`Table selected - ${value}`);
  };

  const fetchProductsData = async (value) => {
    try {
      const response = await axios.get("/assets/json/products-data.json");
      const filteredData = response.data.filter(
        (product) => product.category === value
      );
      setFilteredData(filteredData);
      console.log(filteredData);
    } catch (err) {
    } finally {
    }
  };

  const handleChairSelection = (value, url) => {
    if (selectedChair !== value) {
      setSelectedChair(value);
      setChair_url(url);
    }
    console.log(`Chair selected - ${value}`);
  };

  const deselectIcon = () => {
    setSelectedProduct("");
    setIs_ProductCatalogueVisible(false);
  };

  return (
    <div className="flex gap-1">
      <div className="w-[25%] h-screen  flex flex-col gap-2 p-2">
        <div className="flex justify-between items-center">
          <img className="h-8" src="/assets/icons/logoFull.png" alt="Logo" />
          <FaRegArrowAltCircleLeft className="text-xl rounded bg-orange-30 cursor-pointer" />
        </div>
        <div className="flex justify-between items-center mt-6">
          <p className="font-bold text-md">Choose Products</p>
        </div>
        <div className="grid grid-cols-4 gap-1 items-center">
          <img
            className={`h-12 p-2 rounded-md hover:bg-gray-100 cursor-pointer border border-transparent hover:border-black hover:shadow-md ${
              selectedProduct === "lamp"
                ? "bg-gray-100 border-black shadow-md"
                : ""
            }`}
            src="/assets/icons/ceiling-lamp.png"
            alt="1"
            onClick={() => handleclickIcon("light")}
          />
          <img
            className={`h-12 p-2 rounded-md hover:bg-gray-100 cursor-pointer border border-transparent hover:border-black hover:shadow-md ${
              selectedProduct === "carpet"
                ? "bg-gray-100 border-black shadow-md"
                : ""
            }`}
            src="/assets/icons/carpet.png"
            alt="2"
            onClick={() => handleclickIcon("mat")}
          />
          <img
            className={`h-12 p-2 rounded-md hover:bg-gray-100 cursor-pointer border border-transparent hover:border-black hover:shadow-md ${
              selectedProduct === "chair"
                ? "bg-gray-100 border-black shadow-md"
                : ""
            }`}
            src="/assets/icons/chair.png"
            alt="3"
            onClick={() => handleclickIcon("chair")}
          />
          <img
            className={`h-12 p-2 rounded-md hover:bg-gray-100 cursor-pointer border border-transparent hover:border-black hover:shadow-md ${
              selectedProduct === "table"
                ? "bg-gray-100 border-black shadow-md"
                : ""
            }`}
            src="/assets/icons/side-table.png"
            alt="4"
            onClick={() => handleclickIcon("table")}
          />
          <img
            className={`h-12 p-2 rounded-md hover:bg-gray-100 cursor-pointer border border-transparent hover:border-black hover:shadow-md ${
              selectedProduct === "sink"
                ? "bg-gray-100 border-black shadow-md"
                : ""
            }`}
            src="/assets/icons/sink.png"
            alt="5"
            onClick={() => handleclickIcon("sink")}
          />
          <img
            className={`h-12 p-2 rounded-md hover:bg-gray-100 cursor-pointer border border-transparent hover:border-black hover:shadow-md ${
              selectedProduct === "fridge"
                ? "bg-gray-100 border-black shadow-md"
                : ""
            }`}
            src="/assets/icons/fridge.png"
            alt="6"
            onClick={() => handleclickIcon("fridge")}
          />
          <p className="cursor-pointer" onClick={deselectIcon}>
            Deselect
          </p>
        </div>
      </div>
      <Suspense fallback={<Overlay />}>
        <Canvas flat dpr={[1, 1.5]} gl={{ antialias: false }} shadows>
          <Environment preset="sunset" exposure={0.5} />
          <CameraComponent />
          {/* console.log("Table URL:", table_url); */}

          <Table url={table_url} />
          <Table url={chair_url} />

          <Asset />
        </Canvas>
      </Suspense>

      {is_ProductCatalogueVisible && (
        <div className="w-[30%] h-screen  flex flex-col gap-2 p-2">
          <div>
            Selected Category -{" "}
            <span className="capitalize">{selectedProduct}</span>
          </div>
          <div>
            Product Name - <span className="capitalize">{selectedTable}</span>
          </div>

          <div>Swap Products - {filteredData.length} Available</div>
          <div className="grid grid-cols-2 gap-2">
            {selectedProduct === "table" && (
              <>
                {filteredData.map((product) => (
                  <div
                    key={product.asset}
                    onClick={() =>
                      handleTableSelection(product.asset, product.glb_link)
                    }
                    className={`flex flex-col gap-2 items-center p-2 hover:bg-gray-100 rounded-sm cursor-pointer ${
                      selectedTable === product.asset ? "bg-gray-100" : ""
                    }`}
                  >
                    <img
                      className="h-20"
                      src={product.thumbnail_image}
                      alt={product.asset}
                    />

                    <p className="bg-gray-300 px-2 py-1 font-bold text-xs rounded-sm">
                      Swap Product
                    </p>
                  </div>
                ))}
              </>
            )}

            {selectedProduct === "chair" && (
              <>
                {filteredData.map((product) => (
                  <div
                    key={product.asset}
                    onClick={() =>
                      handleTableSelection(product.asset, product.glb_link)
                    }
                    className={`flex flex-col gap-2 items-center p-2 hover:bg-gray-100 rounded-sm cursor-pointer ${
                      selectedChair === product.asset ? "bg-gray-100" : ""
                    }`}
                  >
                    <img
                      className="h-20"
                      src={product.thumbnail_image}
                      alt={product.asset}
                    />

                    <p className="bg-gray-300 px-2 py-1 font-bold text-xs rounded-sm">
                      Swap Product
                    </p>
                  </div>
                ))}
              </>
            )}

            {selectedProduct === "light" && (
              <>
                {filteredData.map((product) => (
                  <div
                    key={product.asset}
                    onClick={() =>
                      handleTableSelection(product.asset, product.glb_link)
                    }
                    className={`flex flex-col gap-2 items-center p-2 hover:bg-gray-100 rounded-sm cursor-pointer ${
                      selectedChair === product.asset ? "bg-gray-100" : ""
                    }`}
                  >
                    <img
                      className="h-20"
                      src={product.thumbnail_image}
                      alt={product.asset}
                    />

                    <p className="bg-gray-300 px-2 py-1 font-bold text-xs rounded-sm">
                      Swap Product
                    </p>
                  </div>
                ))}
              </>
            )}

            {selectedProduct === "mat" && (
              <>
                {filteredData.map((product) => (
                  <div
                    key={product.asset}
                    onClick={() =>
                      handleTableSelection(product.asset, product.glb_link)
                    }
                    className={`flex flex-col gap-2 items-center p-2 hover:bg-gray-100 rounded-sm cursor-pointer ${
                      selectedTable === product.asset ? "bg-gray-100" : ""
                    }`}
                  >
                    <img
                      className="h-20"
                      src={product.thumbnail_image}
                      alt={product.asset}
                    />

                    <p className="bg-gray-300 px-2 py-1 font-bold text-xs rounded-sm">
                      Swap Product
                    </p>
                  </div>
                ))}
              </>
            )}

            {selectedProduct === "fridge" && (
              <>
                {filteredData.map((product) => (
                  <div
                    key={product.asset}
                    onClick={() =>
                      handleTableSelection(product.asset, product.glb_link)
                    }
                    className={`flex flex-col gap-2 items-center p-2 hover:bg-gray-100 rounded-sm cursor-pointer ${
                      selectedTable === product.asset ? "bg-gray-100" : ""
                    }`}
                  >
                    <img
                      className="h-20"
                      src={product.thumbnail_image}
                      alt={product.asset}
                    />

                    <p className="bg-gray-300 px-2 py-1 font-bold text-xs rounded-sm">
                      Swap Product
                    </p>
                  </div>
                ))}
              </>
            )}

            {selectedProduct === "sink" && (
              <>
                {filteredData.map((product) => (
                  <div
                    key={product.asset}
                    onClick={() =>
                      handleTableSelection(product.asset, product.glb_link)
                    }
                    className={`flex flex-col gap-2 items-center p-2 hover:bg-gray-100 rounded-sm cursor-pointer ${
                      selectedTable === product.asset ? "bg-gray-100" : ""
                    }`}
                  >
                    <img
                      className="h-20"
                      src={product.thumbnail_image}
                      alt={product.asset}
                    />

                    <p className="bg-gray-300 px-2 py-1 font-bold text-xs rounded-sm">
                      Swap Product
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Kitchen02;
