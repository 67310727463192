import React from "react";
import useStore from "../Store";
import { RxCross1 } from "react-icons/rx";

function MiniModel() {
  const {
    isMinimodalopen,
    SetIsMinimodalopen,
    selectedfloor,
    setSelectedfloor,
  } = useStore();
  return (
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors ${
        isMinimodalopen ? "visible bg-black/10" : "invisible"
      }`}
    >
      <div className="absolute top-0 right-0 z-100 w-[30%] bg-white/20 shadow-lg backdrop-blur-md px-8 py-4 h-[100vh]">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <p className="text-3xl">Customize the floor</p>
            <div
              className="p-1 bg-red-700 rounded-full hover:bg-red-800 hover:cursor-pointer"
              onClick={() => SetIsMinimodalopen(false)}
            >
              <RxCross1 className="text-white font-bold" />
            </div>
          </div>

          <div className="grid grid-cols-3 mt-4 gap-2">
            <div
              className="flex flex-col gap-1 p-4 rounded-md shadow-md items-center border border-1 border-transparent"
              onClick={() => setSelectedfloor("floor_01")}
            >
              <img
                src="/assets/thumbnails/floor_01.png"
                className="w-16 h-16 object-cover rounded-full"
                alt=""
              />
              <p>Light Wood</p>
            </div>

            <div
              className="flex flex-col gap-1 items-center p-4 rounded-md shadow-md border border-1 border-transparent"
              onClick={() => setSelectedfloor("floor_02")}
            >
              <img
                src="/assets/thumbnails/floor_02.png"
                className="w-16 h-16 object-cover rounded-full "
                alt=""
              />
              <p>Dark Wood</p>
            </div>

            <div
              className="flex flex-col gap-1 items-center p-4 rounded-md shadow-md border border-1 border-transparent"
              onClick={() => setSelectedfloor("floor_03")}
            >
              <img
                src="/assets/thumbnails/floor_03.png"
                className="w-16 h-16 object-cover rounded-full "
                alt=""
              />
              <p>Bamboo</p>
            </div>

            <div
              className="flex flex-col gap-1 items-center p-4 rounded-md shadow-md border border-1 border-transparent"
              onClick={() => setSelectedfloor("default")}
            >
              <img
                src="/assets/thumbnails/default.png"
                className="w-16 h-16 object-cover rounded-full "
                alt=""
              />
              <p>White</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MiniModel;
