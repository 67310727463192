import React, { useEffect, useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { FaAnglesRight } from "react-icons/fa6";
import useStore from "../Store";
import QRCode from "qrcode.react";

function Model() {
  const {
    isModalOpen,
    closeModal,
    selectedProduct,
    setSelectedTable,
    relatedProducts,
    setSelectedFridge,
    setSelectedProduct,
    setSelectedMixie,
    setSelectedLight,
    setSelectedMat,
    setSelectedChair,
    setSelectedPot,
    setSelectedFlower,
    setSelectedSmalloven,
    setSelectedLargeoven,
    setSelectedTap,
    setSelectedCooker,
    setSelectedDishwash,
    setSelectedSink,
    setSelectedStove,
  } = useStore();

  const [referencetype, setReferencetype] = useState("360");

  const [toggle, setToggle] = useState("swap-products");

  const fetchProductData = async (asset) => {
    const response = await fetch("/assets/json/products-data.json");
    const data = await response.json();
    const selectedProduct = data.find((product) => product.asset === asset);
    setSelectedProduct(selectedProduct);
  };

  const swapFunction = (product, category) => {
    //Select Product :

    if (category === "fridge") {
      setSelectedFridge(product);
    } else if (category === "table") {
      setSelectedTable(product);
    } else if (category === "mixie") {
      setSelectedMixie(product);
    } else if (category === "light") {
      setSelectedLight(product);
    } else if (category === "mat") {
      setSelectedMat(product);
    } else if (category === "chair") {
      setSelectedChair(product);
    } else if (category === "pot") {
      setSelectedPot(product);
    } else if (category === "flower") {
      setSelectedFlower(product);
    } else if (category === "oven_small") {
      setSelectedSmalloven(product);
    } else if (category === "oven_large") {
      setSelectedLargeoven(product);
    } else if (category === "tap") {
      setSelectedTap(product);
    } else if (category === "cooker") {
      setSelectedCooker(product);
    } else if (category === "sink") {
      setSelectedSink(product);
    } else if (category === "dishwasher") {
      setSelectedDishwash(product);
    } else if (category === "stove") {
      setSelectedStove(product);
    }
    fetchProductData(product);
  };

  const StarRating = ({ ratings }) => {
    // Ensure ratings is a number and within the expected range (0-5)
    const starCount = Math.max(0, Math.min(5, ratings));

    return (
      <div className="flex gap-1">
        {Array.from({ length: starCount }, (_, index) => (
          <FaStar key={index} />
        ))}
        {/* Optionally, you can add empty stars for a total of 5 */}
        {Array.from({ length: 5 - starCount }, (_, index) => (
          <FaStar key={index + starCount} className="text-gray-400" /> // Use a different color for empty stars
        ))}
      </div>
    );
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`fixed inset-0 flex justify-center items-center transition-colors ${
        isModalOpen ? "visible black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-white/10 w-[512px] h-full overflow-auto absolute right-0  shadow p-6 transition-all backdrop-blur-[2px] : ${
          isModalOpen
            ? "translate-x opacity-100"
            : "-translate-x-full opacity-0"
        }`}
      >
        {relatedProducts && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-0">
              <div className="flex justify-between items-start">
                <div className="flex gap-6">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-1 items-center">
                      <div
                        onClick={() => setToggle("swap-products")}
                        className={` cursor-pointer ${
                          toggle === "swap-products"
                            ? "font-bold"
                            : "font-medium"
                        }`}
                      >
                        Swap Products
                      </div>
                      <div
                        className={`text-[10px] font-bold text-white px-1 py-[1px] rounded bg-slate-400 ${
                          toggle === "swap-products" ? "" : ""
                        }`}
                      >
                        {relatedProducts.length}
                      </div>
                    </div>
                    <div
                      className={`h-[4px] w-full bg-black/20 ${
                        toggle === "swap-products" ? "visible" : "invisible"
                      }`}
                    ></div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div
                      onClick={() => setToggle("product-info")}
                      className={` cursor-pointer ${
                        toggle === "product-info" ? "font-bold" : "font-light"
                      }`}
                    >
                      Product Info
                    </div>
                    <div
                      className={`h-[4px] w-full bg-black/20 ${
                        toggle === "product-info" ? "visible" : "invisible"
                      }`}
                    ></div>
                  </div>
                </div>
                <div
                  className="text-sm text-red-200 px-2 py-[2px] rounded-full bg-red-700 cursor-pointer hover:bg-red-200 transition-colors hover:text-red-700"
                  onClick={closeModal}
                >
                  X
                </div>
              </div>

              <div className="h-[1px] w-full bg-black/20"></div>
            </div>

            {toggle === "swap-products" && (
              <div className="grid grid-cols-3 gap-2">
                {relatedProducts.map((product, index) => (
                  <>
                    <div
                      key={product.id}
                      onClick={() =>
                        swapFunction(product.asset, product.category)
                      }
                      className="flex flex-col gap-4 bg-white/20 rounded-md border border-1 border-transparent hover:border-slate-100 hover:cursor-pointer p-2"
                    >
                      <img
                        className="h-20 rounded-md"
                        src={product.thumbnail_image}
                        alt=""
                      />
                      <div className="flex gap-1 items-center justify-center bg-slate-800 rounded-md px-2 py-[2px]">
                        <button className="px-2 py-[2px] rounded-md text-white text-xs">
                          Swap This
                        </button>
                        <FaAnglesRight className="text-xs text-white" />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}

            {toggle === "product-info" && (
              <>
                <div className="flex justify-between items-center">
                  <div className="text-2xl">
                    {selectedProduct.name} -{" "}
                    <span className="text-sm font-medium line-through text-slate-400">
                      ${selectedProduct.old_price}
                      {"  "}
                    </span>
                    <span className="text-lg font-semibold px-2 py-1 rounded-md text-red-100 bg-red-700">
                      {" "}
                      ${selectedProduct.price}
                    </span>
                  </div>
                </div>
                <div>{selectedProduct.description}</div>
                <div className="flex gap-2 items-center">
                  <StarRating ratings={selectedProduct.ratings} />
                  <div> {selectedProduct.reviews} Ratings</div>
                </div>
                <div className="rounded-sm ">
                  {referencetype === "360" && (
                    <iframe
                      title="link"
                      className=" h-[400px] w-full border"
                      src={selectedProduct.turnaround_link}
                    ></iframe>
                  )}

                  {/* {referencetype === "renders" && (
                    <img
                      className="h-[400px] object-cover rounded-sm border"
                      src={selectedProduct.render_image}
                      alt=""
                    />
                  )}

                  {referencetype === "videos" && (
                    <iframe
                      title="link"
                      className=" h-[400px] w-full rounded-md border"
                      src={selectedProduct.video_link}
                      frameborder="0"
                    ></iframe>
                  )}

                  {referencetype === "configurator" && (
                    <iframe
                      title="link"
                      className=" h-[400px] w-full rounded-md border"
                      src={selectedProduct.configurator_link}
                      frameborder="0"
                    ></iframe>
                  )} */}
                </div>
                <div className="flex gap-2">
                  {/* <p
                    onClick={() => setReferencetype("renders")}
                    className={`px-2 py-[2px] rounded-md text-sm cursor-pointer   font-semibold hover:bg-slate-700 hover:font-bold hover:text-white ${
                      referencetype === "renders"
                        ? "bg-slate-700 text-white font-bold"
                        : ""
                    }`}
                  >
                    Renders
                  </p>
                  <p
                    onClick={() => setReferencetype("videos")}
                    className={`px-2 py-[2px] rounded-md text-sm cursor-pointer  px-2 py-[2px] rounded-md text-sm cursor-pointer font-semibold hover:bg-slate-700 hover:font-bold hover:text-white ${
                      referencetype === "videos"
                        ? "bg-slate-700 text-white font-bold"
                        : ""
                    }`}
                  >
                    Video
                  </p> */}
                  <p
                    onClick={() => setReferencetype("360")}
                    className={`px-2 py-[2px] rounded-md text-sm cursor-pointer   font-semibold hover:bg-slate-700 hover:font-bold hover:text-white ${
                      referencetype === "360"
                        ? "bg-slate-700 text-white font-bold"
                        : ""
                    }`}
                  >
                    360
                  </p>
                  {/* <p
                    onClick={() => setReferencetype("configurator")}
                    className={`px-2 py-[2px] rounded-md text-sm cursor-pointer  px-2 py-[2px] rounded-md text-sm cursor-pointer font-semibold hover:bg-slate-700 hover:font-bold hover:text-white ${
                      referencetype === "configurator"
                        ? "bg-slate-700 text-white font-bold"
                        : ""
                    }`}
                  >
                    Configurator
                  </p> */}
                </div>

                <div className="bg-orange-100/50 p-3 text-sm text-orange-700 rounded-md flex justify-between  gap-2 ">
                  <div>
                    <IoMdInformationCircle className="text-xl" />
                  </div>
                  <div>
                    <p className="font-bold">Product Disclaimer :</p>
                    <p>
                      The product is provided as-is without warranties of any
                      kind. Use at your own risk; we are not liable for any
                      damages. Consult a professional to ensure it meets your
                      needs.
                    </p>
                  </div>
                </div>
                <p className="px-2 py-1 font-bold text-lg">Try in Your Room</p>
                <div className="flex items-center justify-center bg-white/40 p-4">
                  <QRCode
                    className="rounded-md"
                    value={selectedProduct.turnaround_link}
                    size={256}
                  />
                </div>
                <div className="w-full flex gap-2">
                  <div className="py-2 w-full secondary flex items-center justify-center rounded-md">
                    <p className="">Move to Wishlist</p>
                  </div>
                  <div className="py-2 w-full primary flex items-center justify-center rounded-md">
                    <p className="">Add to Cart</p>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Model;
